/* Bootstrap Variables */
/* Bootstrap Variables */
/* Offcanvas */
.offcanvas {
  border: none;
}
.offcanvas-top {
  bottom: auto;
}
.offcanvas-header {
  padding: 16px 24px;
  background-color: #221F1E;
}
.offcanvas-title {
  color: #FFFFFF;
}
.offcanvas .btn-close {
  color: #FFFFFF;
  background-image: none;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
}
.offcanvas .btn-close:focus {
  box-shadow: none;
}
.offcanvas-body {
  display: flex;
  flex-direction: column;
  padding: 24px;
}
.offcanvas-search {
  height: auto;
}
.offcanvas-search .offcanvas-body {
  padding-bottom: 30px;
}

/* Header */
.ht-header {
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
}
.ht-header-transparent {
  background-color: transparent;
}
.ht-header-fluid {
  padding: 0 70px;
}
@media only screen and (min-width: 1200px) and (max-width: 1549px) {
  .ht-header-fluid {
    padding: 0 40px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .ht-header-fluid {
    padding: 0 15px;
  }
}
@media only screen and (max-width: 767px) {
  .ht-header-fluid {
    padding: 0;
  }
}
.ht-header-top {
  padding-top: 12px;
  padding-bottom: 12px;
}
@media only screen and (max-width: 767px) {
  .ht-header-top {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.ht-header-logo {
  line-height: 1;
  display: flex;
  padding: 30px 0;
}
@media only screen and (max-width: 767px) {
  .ht-header-logo {
    padding: 25px 0;
  }
}
.ht-header-menu > ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
  gap: 40px;
}
@media only screen and (min-width: 1200px) and (max-width: 1549px) {
  .ht-header-menu > ul {
    gap: 30px;
  }
}
.ht-header-menu > ul > li {
  position: relative;
}
.ht-header-menu > ul > li > a {
  line-height: 30px;
  display: flex;
  align-items: center;
  padding: 30px 0;
  text-transform: uppercase;
  color: #000000;
  gap: 8px;
}
.ht-header-menu > ul > li > a:hover {
  color: #5463FF;
}
.ht-header-menu > ul > li:hover .ht-sub-menu {
  visibility: visible;
  margin-top: 0;
  opacity: 1;
}
.ht-header-menu-height-2 > ul > li > a {
  padding: 15px 0;
}
.ht-header-menu-light > ul > li > a {
  color: rgba(255, 255, 255, 0.85);
}
.ht-header-menu-light > ul > li > a:hover {
  color: #5463FF;
}
.ht-header-menu .ht-sub-menu {
  position: absolute;
  top: 100%;
  visibility: hidden;
  width: 250px;
  margin: 0;
  margin-top: 20px;
  margin-left: -30px;
  padding: 20px 30px;
  list-style: none;
  transition: all 0.4s ease 0s;
  opacity: 0;
  background-color: #FFFFFF;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
}
.ht-header-menu .ht-sub-menu-arrow {
  font-size: 14px;
}
.ht-header-menu .ht-sub-menu li a {
  line-height: 20px;
  display: block;
  padding: 10px 0;
  color: #000000;
}
.ht-header-menu .ht-sub-menu li a:hover {
  color: #5463FF;
}
.ht-header-info {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
  gap: 25px;
}
.ht-header-info li a {
  font-size: 14px;
  line-height: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 6px;
}
.ht-header-info-light {
  color: rgba(255, 255, 255, 0.85);
}
.ht-header-info-light li a:hover {
  color: #FFFFFF;
}
.ht-header-lang-curr {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.ht-header-lang .dropdown-toggle, .ht-header-curr .dropdown-toggle {
  font-size: 14px;
  line-height: 1;
  display: flex;
  align-items: center;
}
.ht-header-lang .dropdown-menu, .ht-header-curr .dropdown-menu {
  min-width: auto;
  padding: 10px 20px;
  border: none;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.15);
}
.ht-header-lang .dropdown-menu .dropdown-item, .ht-header-curr .dropdown-menu .dropdown-item {
  padding: 5px 0;
  color: #515151;
}
.ht-header-lang .dropdown-menu .dropdown-item.active, .ht-header-lang .dropdown-menu .dropdown-item:hover, .ht-header-curr .dropdown-menu .dropdown-item.active, .ht-header-curr .dropdown-menu .dropdown-item:hover {
  background-color: transparent;
}
.ht-header-lang .dropdown-menu .dropdown-item:hover, .ht-header-curr .dropdown-menu .dropdown-item:hover {
  color: #000000;
}
.ht-header-lang .dropdown-menu .dropdown-item.active, .ht-header-curr .dropdown-menu .dropdown-item.active {
  color: #5463FF;
}
.ht-header-lang-light .dropdown-toggle, .ht-header-curr-light .dropdown-toggle {
  color: rgba(255, 255, 255, 0.85);
}
.ht-header-lang-light .dropdown-toggle:hover, .ht-header-curr-light .dropdown-toggle:hover {
  color: #FFFFFF;
}
.ht-header-search, .ht-header-search-2 {
  position: relative;
  display: flex;
}
.ht-header-search form, .ht-header-search-2 form {
  position: relative;
}
.ht-header-search-field, .ht-header-search-2-field {
  font-size: 14px;
  line-height: 20px;
  width: 250px;
  padding: 10px 20px;
  padding-right: 50px;
  border: 1px solid #E5E5E5;
  border-radius: 100px;
  background-color: transparent;
}
.ht-header-search-field::-webkit-input-placeholder, .ht-header-search-2-field::-webkit-input-placeholder {
  color: rgba(81, 81, 81, 0.75);
}
.ht-header-search-field:-moz-placeholder, .ht-header-search-2-field:-moz-placeholder {
  color: rgba(81, 81, 81, 0.75);
}
.ht-header-search-field::-moz-placeholder, .ht-header-search-2-field::-moz-placeholder {
  color: rgba(81, 81, 81, 0.75);
}
.ht-header-search-field:-ms-input-placeholder, .ht-header-search-2-field:-ms-input-placeholder {
  color: rgba(81, 81, 81, 0.75);
}
.ht-header-search-field:focus, .ht-header-search-2-field:focus {
  border-color: #5463FF;
}
.ht-header-search-submit, .ht-header-search-2-submit {
  line-height: 20px;
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  width: 40px;
  padding: 10px;
  border: none;
  background-color: transparent;
}
.ht-header-search-submit:hover, .ht-header-search-2-submit:hover {
  color: #5463FF;
}
.ht-header-search-light .ht-header-search-field, .ht-header-search-2-light .ht-header-search-field {
  color: #FFFFFF;
  border-color: rgba(255, 255, 255, 0.85);
}
.ht-header-search-light .ht-header-search-field::-webkit-input-placeholder, .ht-header-search-2-light .ht-header-search-field::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.85);
}
.ht-header-search-light .ht-header-search-field:-moz-placeholder, .ht-header-search-2-light .ht-header-search-field:-moz-placeholder {
  color: rgba(255, 255, 255, 0.85);
}
.ht-header-search-light .ht-header-search-field::-moz-placeholder, .ht-header-search-2-light .ht-header-search-field::-moz-placeholder {
  color: rgba(255, 255, 255, 0.85);
}
.ht-header-search-light .ht-header-search-field:-ms-input-placeholder, .ht-header-search-2-light .ht-header-search-field:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.85);
}
.ht-header-search-light .ht-header-search-submit, .ht-header-search-2-light .ht-header-search-submit {
  color: rgba(255, 255, 255, 0.85);
}
.ht-header-search-light .ht-header-search-submit:hover, .ht-header-search-2-light .ht-header-search-submit:hover {
  color: #5463FF;
}
.ht-header-search-2 {
  width: 100%;
  max-width: 750px;
  margin: auto;
}
.ht-header-search-2 form {
  width: 100%;
}
.ht-header-search-2-field {
  font-size: 24px;
  line-height: 30px;
  width: 100%;
  padding: 20px 0;
  padding-right: 70px;
  border: none;
  border-bottom: 1px solid #E5E5E5;
  border-radius: 0;
}
.ht-header-search-2-submit {
  line-height: 20px;
  width: auto;
  padding: 10px;
}
.ht-header-social {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
.ht-header-social a {
  display: block;
  padding: 5px;
}
.ht-header-social a i {
  font-size: 16px;
  line-height: 1;
  display: flex;
}
.ht-header-social-light a {
  color: rgba(255, 255, 255, 0.85);
}
.ht-header-social-light a:hover {
  color: #5463FF;
}
.ht-header-action {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
@media only screen and (max-width: 479px) {
  .ht-header-action {
    gap: 15px;
  }
}
.ht-header-action-btn {
  font-size: 14px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0;
  border: none;
  background-color: transparent;
  gap: 6px;
}
.ht-header-action-btn-wishlist, .ht-header-action-btn-cart {
  margin-right: 9px;
}
.ht-header-action-btn i {
  font-size: 20px;
}
@media only screen and (max-width: 479px) {
  .ht-header-action-btn svg {
    width: 20px;
    height: 20px;
  }
}
.ht-header-action-btn-badge {
  font-size: 11px;
  line-height: 1;
  position: absolute;
  right: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  transform: translateX(50%) translateY(-50%);
  color: #FFFFFF;
  border-radius: 50%;
  background-color: #5463FF;
}
.ht-header-action-btn:hover {
  color: #5463FF;
}
.ht-header-action-light .ht-header-action-btn {
  color: rgba(255, 255, 255, 0.85);
}
.ht-header-action-light .ht-header-action-btn:hover {
  color: #5463FF;
}

/* Mobile Menu */
.ht-mobile-menu {
  overflow: auto;
  flex: 1 0;
  max-height: 100%;
  scrollbar-width: thin;
}
.ht-mobile-menu > ul {
  margin: 0;
  padding: 0;
  list-style: none;
  border-top: 1px solid #E5E5E5;
  border-bottom: 1px solid #E5E5E5;
}
.ht-mobile-menu > ul > li {
  position: relative;
}
.ht-mobile-menu > ul > li:not(:last-child) {
  border-bottom: 1px solid #E5E5E5;
}
.ht-mobile-menu > ul > li > a {
  font-weight: 500;
  line-height: 20px;
  display: block;
  padding: 15px 0;
  text-transform: uppercase;
  color: #000000;
}
.ht-mobile-menu > ul > li > a:hover {
  color: #5463FF;
}
.ht-mobile-menu > ul > li .ht-sub-menu-toggle {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 40px;
  height: 50px;
  cursor: pointer;
  color: #000000;
  border: none;
  background-color: transparent;
}
.ht-mobile-menu > ul > li .ht-sub-menu-toggle::before, .ht-mobile-menu > ul > li .ht-sub-menu-toggle::after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 12px;
  height: 2px;
  content: "";
  transition: all 0.4s ease 0s;
  transform: translateY(-50%) translateX(-50%);
  background-color: currentColor;
}
.ht-mobile-menu > ul > li .ht-sub-menu-toggle::after {
  transform: translateY(-50%) translateX(-50%) rotate(90deg);
}
.ht-mobile-menu > ul > li .ht-sub-menu-toggle.active::after {
  transform: translateY(-50%) translateX(-50%);
}

/* Mobile Sub Menu */
.ht-mobile-sub-menu {
  display: none;
  margin: 0;
  padding: 0;
  list-style: none;
  border-top: 1px solid #E5E5E5;
}
.ht-mobile-sub-menu li:not(:last-child) {
  border-bottom: 1px solid #E5E5E5;
}
.ht-mobile-sub-menu li a {
  font-size: 15px;
  line-height: 20px;
  display: block;
  padding: 13px 15px;
  color: #000000;
}
.ht-mobile-sub-menu li a:hover {
  color: #5463FF;
}
.ht-mobile-sub-menu li .ht-sub-menu-toggle {
  height: 46px;
}

/* Header Cart */
.ht-cart-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.ht-cart-body {
  overflow: auto;
  flex: 1 0;
  scrollbar-width: thin;
}
.ht-cart-product {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.ht-cart-product:not(:last-child) {
  margin-bottom: 20px;
}
.ht-cart-product-thumb {
  position: relative;
  width: 75px;
}
.ht-cart-product-remove {
  font-size: 14px;
  line-height: 1;
  position: absolute;
  top: 0;
  left: 0;
  padding: 2px;
  color: #FFFFFF;
  border: none;
  border-radius: 50%;
  border-top-left-radius: 0;
  background-color: #DC3545;
}
.ht-cart-product-content {
  flex: 1 0;
}
.ht-cart-product-title {
  font-size: 16px;
}
.ht-cart-product-quantity {
  font-size: 14px;
}
.ht-cart-footer {
  margin-top: 30px;
}
.ht-cart-total {
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  margin: 0;
  margin-bottom: 20px;
  padding: 15px 0;
  border-top: 1px solid #E5E5E5;
  border-bottom: 1px solid #E5E5E5;
}
.ht-cart-action {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
.ht-cart-action-btn {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  flex: 1 0;
  justify-content: center;
  padding: 12px 10px 10px;
  text-transform: uppercase;
  color: #000000;
  border: 1px solid #E5E5E5;
  background-color: transparent;
}
.ht-cart-action-btn:hover {
  color: #FFFFFF;
  border-color: #5463FF;
  background-color: #5463FF;
}